@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body{
    font-family: "Roboto Condensed" sans-serif !important;
}

:root{
    --primary-white:#fff;
    --primary-orange:#f9ab00;
    --primary-red:#ef4035;
    --primary-light-grey:#f4f4f4;
    --primary-grey:#ccd1d9;
    --primary-dark-grey:#666666;
    --primary-dark:rgb(52,58,64);
    --primary-black:#000000;
    --primary-green: green;
    --primary-hover-red: #f33501;

}
nav{
    z-index: 2;
}

/*............MenuLinks...*/
nav a.nav-link{
    font-size: small;
    letter-spacing: 0.0375rem;
    cursor: pointer;
}

nav ul li{
    text-transform: uppercase;
}
.navbar-nav > li >a {
    color: var(--primary-white) !important;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    border-top: 0.1875rem solid transparent;
}
.navbar-nav >li>a.active, .navbar-nav>li>a.active:focus{
    border-top: 0.1875rem solid var(--primary-orange);
}
.navbar-nav>li>a:hover{
    border-top: 0.1875rem solid var(--primary-orange);

}
/**************Header**********************/

.header-wraper{
    position: relative;
    background: url(2.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.main-info{
display: flex;
position: absolute;
top: 200px;
left: 0;
right: 0;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
z-index: 1;
}
/*******TypedTExt**********/
.typed-text{
margin-top: 10px;
font-size: 2rem;
color: #fff;
}
.btn-main-offer{
    border: 1px solid var(--primary-red);
    text-transform: uppercase bold;
    border-radius: 0%;
    margin-top: 200px;
    padding: 0.625rem 0.75rem;
    color:var(--primary-white);
    margin: 2rem;
}
.btn-main-offer:hover{
    text-decoration: none;
    background-color:var(--primary-red);
    color: var(--primary-white);
    transition: .2s ease-in-out;
}

/**********PARTICALS********/
.tsparticles-canvas-el{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    
}

@media(max-width:768px){
    .particles-canvas{
        display: none;
    }
    .main-info h1{
        font-size: 1.5rem !important;
    }
    .typed-text{
        font-size: 1.3rem;
    }
}

/******About Me**********/


.photo-wrap{
 display: flex;
 justify-content: center;
 align-items: center;
    
}
.profile-img{
    border: 2px solid var(--primary-orange);
    padding: 0.3rem;
    border-radius: 50%;
    width: 18rem;
    height: 20rem;
}
.about-heading{
color: #fcca03;
text-transform: uppercase;
}
.about-description {
    font-size: 18px;
    color: var(--primary-dark-grey);
}
@media(max-width:468px){
    .profile-img{
        width: 10rem;
        height: 10rem;
        
    }
}

/***********SKILLS******************/
.skills{
text-align: center;
background: var(--primary-light-grey);

}
.devicon{
    margin-top: 0.3rem;
    
}

.devicon i{
    font-size: 5rem;
    margin:0.3125rem 0.3125rem  1rem  0.3125rem;
    align-items: center;
}
.box{
    height: 90%;
    padding: 0.625rem;
    background: var(--primary-white);
    margin-bottom: 1.875rem;
    
}

/***********Experience***********/
.experience{
padding: 00.75rem;
}

.experience-wrapper{
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
}

.experience h1{
    text-transform: uppercase;
    color: var(--primary-orange);
}

.experience-wrapper::before{
    position: absolute;
    content: "";
    top:0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: var(--primary-grey);
    z-index: 1;
}
.timeline-block{
    width: calc( 50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;

}

.timeline-block-right{
    float: right;
}
.timeline-block-left{
    float: left;
    direction: rtl;
}

.marker{
    width: 1rem;
   height: 1rem;
   border-radius: 50%;
   border: 0.3125rem solid var(--primary-dark);
   background: var(--primary-orange);
   margin-top: 0.625rem;
   z-index: 3;
}

.timeline-content {
    width: 95%;
    padding: 0.9375rem;
    color: var(--primary-dark-grey);
    direction: ltr;
    


}
.timeline-content h3{

    margin-top: 0.3125rem;
color: var(--primary-red);
font-weight: 900;
}

/********MEDIA******/
@media(max-width:768px){
.experience .container{
padding-left: 0;
}


    .experience-wrapper::before{
        left: 0.5rem;
        margin-bottom: 1.875rem;

    }
    .timeline-block-right{
        float: none;
        width: 100%;
    }
    .timeline-block-left{
        float: none;
        direction: ltr;
        width: 100%;
        
    }
}

/******PORTFOLIO***********/
.portfolio-wrapper{
background: var(--primary-light-grey) ;
padding: 3rem 0;

}
.portfolio-wrapper h1{
    color: var(--primary-orange );
}
.portfolio-image{
   width: 15rem;
   border: 1px solid var(--primary-orange); 
   padding: 0 0.5rem;
}
.portfolio-image-box{
    position: relative;
    margin:1rem;
}
.overflow{
position: absolute;
opacity: 0;
background: var(--primary-black);
width: 15rem;
height: 8rem;
top: 0;
border: 1 px solid var(--primary-red);
cursor: pointer;

}
.portfolio-image-box:hover .overflow{
    opacity: 60%;
    transition: 0.3s ease-in-out;
}

.portfolio-icon{
    position: absolute;
    opacity: 0;
    margin-left: -8rem;
    margin-top: 3.6rem;
    color: var(--primary-orange);
    font-size: 1.5rem;

}
.portfolio-image-box:hover .portfolio-icon{
    opacity: 70%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.portfolio-img-popup{
    width: 45rem;
    padding: 0 0.5rem;
}

.portofolio-links{
    cursor: pointer;
    color: var(--primary-orange);

}
.portofolio-links:hover{
    color: var(--primary-red);
}
/*******POPUP*******/
.popupbox-wrapper{
    margin-top: 5rem;
}


@media(max-width:768px){

    .portfolio-img-popup{
        width:100%;
    
    }
}
@media(max-height:570px){
    .popupbox-wrapper{
        height: 100% ;
    }
    .portfolio-img-popup{
        width:50%;
    
    }
}
@media(max-height:280px){
    .popupbox-wrapper{
        height: 90%;
    }
    .portfolio-img-popup{
        width:30%;
    
    }
}

/************************ CONTACTS COMPONENT *********************************/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/*------------------ INPUTS -------------------------------*/
.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}
/*---------------- MEDIA -----------------------*/
@media(max-width: 768px) {
    .contacts {
      overflow-x: hidden;
    }
    
    .contacts .container {
      padding: 0 1rem;
    }
  
    .contacts p {
      padding: 0 0.4rem;
    }
  }

/************************ FOOTER COMPONENT *********************************/
.footer {
    background: var(--primary-black);
    color: var(--primary-dark-grey);
    padding: 3rem;
  }
  
  .footer a {
    text-decoration: none;
    color: var(--primary-dark-grey);
  }
  
  .footer a:hover {
    text-decoration: none;
    color: var(--primary-orange);
    cursor:pointer;
  }
  
  .footer p {
    margin-bottom: 0;
  }
.footer-icons{
    cursor: pointer;
}  